<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-md-9 col-lg-9 col-sm-12">
                <h4>
                  Additional assessment(s) for
                  {{ `${exam.title}` }} </h4>
              </div>

              <div class="col-md-3 col-lg-3 col-sm-12 text-right mb-3">
                <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <router-link  :to="{name:'results-view-all-courses-students',params:{slug:exam.slug, 'courseId':courseId}}" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                        <span class="navi-text">Course Result entry</span>
                      </router-link>
                    </b-dropdown-text>

                  </div>
                  <!--end::Navigation-->
                </b-dropdown>
              </div>

              <div class="row mb-5" style="background-color:whitesmoke">
                <div class="col-md-6">
                  <b>Course: </b> {{ course && course.title ? course.title : null }} <br>
                  <b>Program: </b> {{ (exam && exam.program) ? exam.program.title : null }} <br>
                  <b>Semester/year: </b> {{ (exam && exam.grade) ? exam.grade.title : null }} <br>
                </div>

                <div class="col-md-6">
                  <b>Exam title: </b> {{ exam ? exam.title : null }} <br>
                  <b>Exam type: </b> {{ exam && exam.type ? exam.type.toUpperCase() : null }} <br>
                </div>


              </div>
              <div class="col-12">
                <p v-if="exam && exam.program && exam.program.id">Additional assessments can be configured under General program settings. Click <router-link :to="{name:'program-update',params:{id:exam.program.id}}">here</router-link> to change settings for this programme.</p>
                <p v-if="course">Each course can be configured with additional assessments of its own. Click <router-link :to="{name:'course-assessment',params:{id:course.id}}">here</router-link> to view additional assessment for this course.</p>
                <div class="">
                  <table class="table table-stripe">
                    <thead>
                      <th>Additional assessment name</th>
                      <th>Total marks</th>
                      <th class="text-center">Action</th>
                    </thead>
                    <tbody v-if="additionalAssessments && additionalAssessments.length>0">
                      <tr v-for="(assessment, index) of additionalAssessments" :key="index">
                      <td>
                        <router-link
                            :to="{name:'results-view-courses-assessments-student',params:{slug:exam.slug, 'courseId':courseId,'assessmentId':assessment.id}}"
                            class="mr-2"> {{
                            assessment.title
                          }}
                        </router-link>
                      </td>
                      <td>
                        {{ assessment.mark }}
                      </td>

                      <td class="text-center">
                        <div class="kt-widget__toolbar">

                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown"
                              no-caret
                              right
                              no-flip
                              text="Actions"

                          >
                            <template v-slot:button-content>
                              <a href="#" class="" data-toggle="dropdown">
                                <!--              <span class="svg-icon svg-icon-success svg-icon-2x">-->
                                <!--begin::Svg Icon-->
                                <i class="flaticon-more-1"></i>
                                <!--end::Svg Icon-->
                                <!--              </span>-->
                              </a>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover">

                              <b-dropdown-text tag="div" class="navi-item">
                                <router-link
                                    :to="{name:'results-view-courses-assessments-student',params:{slug:exam.slug, 'courseId':courseId,'assessmentId':assessment.id}}"

                                    class="navi-link">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-user-tie"></i>
                                                                    </span>
                                  <span class="navi-text">Assessment marks entry</span>
                                </router-link>
                              </b-dropdown-text>


                              <b-dropdown-text tag="div" class="navi-item">
                                <a href="#" @click="downloadAssessmentImportCsv(exam.id,assessment.id)"
                                   class="navi-link">
                                                                   <span class="navi-icon">
                                                                      <i class="fa fa-file-download"></i>
                                                                    </span>
                                  <span class="navi-text"> Download import file (excel)</span>

                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="openDialog(assessment)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-cloud-upload"></i>
                                </span>
                                  <span class="navi-text">Import result (excel)</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </div>

                      </td>
                    </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="50">No data available to display.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row" v-if="academic_classes.length>0">
                <div class="col-12 text-center">
                  <b-pagination
                      @input="getAdditionalAssessments"
                      v-model="page"
                      :total-rows="total"
                      :per-page="perPage"
                      first-number
                      last-number
                  ></b-pagination>
                  <!--  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
        v-model="dialog"
        width="900"
        title="Result import"
    >
      <v-card>
        <v-card-title>
          {{dialog_title}}
        </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <v-file-input outlined dense v-model="excel_file"
                            accept=".xlsx, .xls, .csv"
                            label="File input"
                            :error="$v.excel_file.$error"
              ></v-file-input>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="cancel-btn"
              @click="closeDialog"
          >
            Close
          </v-btn>

          <v-btn :loading="isLoading"
                 class="text-white ml-2 btn btn-primary"
                 depressed
                 @click="importResultAssessment"
          >
            Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import ExamService from "@/core/services/exam/ExamService";
import CoursesService from "@/core/services/courses/CoursesService";
import {mapGetters} from "vuex";
import {API_URL} from "@/core/config";

const examService = new ExamService();
const courseService = new CoursesService();
const academicClassService = new AcademicClassService();
import AdditionalAssessment from "@/core/services/additionalAssessment/AdditionalAssessmentService";

const additionalAssessment = new AdditionalAssessment();

export default {
  name: "course-assessment",
  components: {},
  validations: {
    excel_file: {required}
  },
  data() {
    return {
      dialog: false,
      dialog_title: 'Import assessment result',
      excel_file: null,
      resultSummary: false,
      isLoading: false,
      academic_classes: [],
      classIds: [],
      course: [],
      courses: [],
      additionalAssessments: [],
      exam: [],
      academic_id: null,
      page: null,
      total: null,
      perPage: null
    };
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    courseId() {
      return this.$route.params.courseId;
    },
    ...mapGetters(["currentUser"])
  },
  mounted() {
    this.$tabs.refresh()
    this.getExam();


  },
  methods: {
    checkUser(courseId) {
      if (this.currentUser.type == 'admin') {
        return true;
      } else if (this.currentUser.type == 'teacher' && this.academic_classes && this.classIds.includes(courseId)) {

        return true;
      } else {
        return false;
      }

    },
    back() {
      this.$router.go(-1)
    },
    getAdditionalAssessments() {
      this.$bus.emit('toggleLoader');
      let data = {academic_year: this.exam.academic_year_id, status:'active'}
      additionalAssessment.paginate(this.courseId, data).then(response => {
        this.additionalAssessments = response.data.data;
      }).catch(error => {
        this.$snotify.error("Something went wrong")
      }).finally(() => {
        this.$bus.emit('toggleLoader');
      });
    },
    getCourse() {
      courseService.show(this.courseId).then(response => {
        this.course = response.data.courses;
      }).catch(error => {
        this.$snotify.error("Something went wrong")
      }).finally(() => {

      });
    },
    getExam() {
      this.$bus.emit('toggleLoader');
      examService.getBySlug(this.slug).then(response => {
        this.exam = response.data.exams;
       // this.getCourses();
        this.getCourse();
        this.getAdditionalAssessments();
      }).catch((err) => {
        //  console.log(err)
      }).finally(() => {
        this.$bus.emit('toggleLoader');
      });
    },
    getCourses() {
      examService.getAssignedCourses(this.exam.id).then(response => {
        this.courses = response.data.courses;
      })
    },
    openDialog(assessment) {
      this.dialog_title = 'Import assessment result for '+assessment.title+ '('+this.course.title+')';
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    importResultAssessment() {
      this.$v.excel_file.$touch();
      if (this.$v.excel_file.$error) {
        setTimeout(() => {
          this.$v.excel_file.$reset();
        }, 3000);
      } else {
          this.isLoading = true
          let fd = new FormData();
          fd.append("file", this.excel_file);
          additionalAssessment.importCourseAssessmentResult(this.exam.id, fd).then(response => {
            this.isLoading = false
            this.excel_file = null;
            this.$snotify.success("Assessment Result Imported")
            this.examId = null;
            this.dialog = false
            this.getExams();
          })
      }
    },

    downloadAssessmentImportCsv(examId, assessmentId) {
      let url = "";
      const data = {
        exam_id: examId,
        assessment_id: assessmentId,
        course_id: this.$route.params.courseId,
      };
      const queryString = new URLSearchParams(data).toString();

      if (queryString) {
        url += `?${queryString}`;
      }
      window.open(
          API_URL + "user/download/import/course/assessment" + url,
          "_blank"
      );
    }
  }
};
</script>
