import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config'
const apiService = Apiservice;


export default class AdditionalAssessmentService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user`;

      }
      paginate(courseId, data) {
            let url = `${this.#api}/${courseId}/additional-assessment`;
            let param = {
                  params: data
            }
            return apiService.query(url,param);
      }

      update(courseId,additionalAssessmentId, data) {
            let url = `${this.#api}/${courseId}/additional-assessment/${additionalAssessmentId}`
            return apiService.put(url, data);

      }

      store(courseId, data) {
            let url = `${this.#api}/${courseId}/additional-assessment`
            return apiService.post(url, data);

      }
      syncStudents(id, data) {
            let url = `${this.#api}/additional-assessment/${id}/sync-students`
            return apiService.post(url, data);

      }
      getAssignedStudents(gradeId,additionalAssessmentId,data={})
      {
            let url = `${this.#api}/additional-assessment/grade/${gradeId}/assigned/${additionalAssessmentId}/students`;
            let param ={
                  params: data
            }
            return apiService.query(url,param);
           /* return apiService.get(url);*/
      }

      show(courseId, additionalAssessmentId) {
            let url = `${this.#api}/${courseId}/additional-assessment/${additionalAssessmentId}`
            return apiService.get(url);
      }

      importCourseAssessmentResult(examId, data) {
            let url = `${this.#api}/additional-assessment/${examId}/import-course-assessment-result`
            return apiService.post(url, data);
      }

      delete(courseId, additionalAssessmentId) {
            let url = `${this.#api}/${courseId}/additional-assessment/${additionalAssessmentId}`
            return apiService.delete(url);
      }

      checkCourseAdditionalAssessment(data){
            let url = `${this.#api}/check/active/additional-assessment`
            return apiService.post(url, data);
      }
}
